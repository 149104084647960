import useFileOrNodePicker, {
  FilePickerHandler,
} from '@features/pickers/hooks/useFileOrNodePicker'
import { NodeType, Node } from 'app/models'

interface Props<Multiple extends boolean> {
  multiple: Multiple
  onFilePicked: FilePickerHandler<Multiple>
  onNodePicked: (node: Node & { type: NodeType.IMAGE }) => void
}
const useImagePicker = <Multiple extends boolean>({
  multiple,
  onFilePicked,
  onNodePicked,
}: Props<Multiple>) =>
  useFileOrNodePicker<Node & { type: NodeType.IMAGE }, Multiple>({
    acceptTypes: 'image/png, image/gif, image/jpeg',
    enabledNodeTypes: [NodeType.IMAGE, NodeType.FOLDER, NodeType.PAGE],
    fileType: NodeType.IMAGE,
    multiple,
    onFilePicked,
    onNodePicked,
  })

export default useImagePicker
