import { useState } from 'react'
import {
  AnimatedDialog,
  ButtonNew,
  ReplaceIcon,
  useDialog,
} from 'boards-web-ui'
import { Trans, useTranslation } from 'react-i18next'

import CloseButtonWithTooltip from '@ui/components/CloseButtonWithTooltip'
import DoneButton from '@ui/components/DoneButton'

import { IconWrapper } from '@ui/components/IconWrapper'
import useOpenVideoStreamingPicker from '@features/nodeEditor/components/VideoStreaming/useOpenVideoStreamingPicker'
import useVideoStreamingFileUpload from '@features/nodeEditor/components/VideoStreaming/useVideoStreamingFileUpload'
import useSaveVideoStreamingNode from '@features/nodeEditor/components/VideoStreaming/useSaveVideoStreamingNode'
import { VideoMetaData } from '@hooks/useUploadFileMetadata'

import styles from '@features/nodeEditor/components/EditImage.module.css'
import { VideoStreamingUploadingProgress } from './VideoStreamingUploadingProgress'
import InputModalTitle from '../../../../../elements/InputModalTitle'
import ModalEditWithConfirmation from '../../../../../components/ModalEditWithConfirmation'
import { Node, NodeType } from '../../../../models'
import { ICreateOrUpdateNode } from '../../models/IUseNodeCreatorAPI'
import useNodeAPI from '../../hooks/useNodeAPI'
import useNodeDiscardChanges from '../../hooks/useNodeDiscardChanges'
import VideoStreaming from '../VideoStreaming'

interface Props {
  node: Node
  boardId: string
  folderId: string
  isPageEditor?: boolean
  onSave: ICreateOrUpdateNode
}

const EditVideoStreaming = ({
  node: currentNode,
  boardId,
  folderId,

  isPageEditor,
  onSave,
}: Props) => {
  const { t } = useTranslation()
  const { close } = useDialog()
  const discardChanges = useNodeDiscardChanges()
  const [node, setNode] = useState<Node | undefined>(currentNode)

  const { title, hasChanges, setTitle } = useNodeAPI(
    currentNode?.title || '',
    '',
  )
  const saveNodeOnDatabase = useSaveVideoStreamingNode({
    currentNodeId: currentNode.id,
    title,
    onSave,
    setNode,
  })
  const { error, progress, isUploading, retryUpload, handleFilePicked } =
    useVideoStreamingFileUpload({
      onUploaded: saveNodeOnDatabase,
    })

  const handleNodePicked = (
    nodePicked: Node & { type: NodeType.VIDEO_STREAMING },
  ) => {
    const metadata: VideoMetaData = {
      duration: nodePicked.duration || 0,
      width: nodePicked.width || 0,
      height: nodePicked.height || 0,
    }
    const savedNode = saveNodeOnDatabase(nodePicked.text, metadata)
    setNode(savedNode)
  }

  const openVideoStreamingPicker = useOpenVideoStreamingPicker({
    onFilePicked: handleFilePicked,
    onNodePicked: handleNodePicked,
  })

  const handleSave = () => {
    if (hasChanges) {
      onSave({ ...currentNode, ...node, title })
    }

    close()
  }

  const handleClose = () => {
    if (hasChanges) {
      discardChanges()
      return
    }

    close()
  }

  if (!currentNode) return null

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        onEscape: handleSave,
        onOverlayClick: handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            <div className={styles.FooterControl}>
              {isUploading ? null : (
                <ButtonNew
                  color={'secondary'}
                  size={'medium'}
                  onClick={openVideoStreamingPicker}
                  startIcon={
                    <IconWrapper>
                      <ReplaceIcon />
                    </IconWrapper>
                  }
                >
                  <Trans i18nKey="replace_video" />
                </ButtonNew>
              )}

              <DoneButton onClick={handleSave} disabled={isUploading}>
                <Trans i18nKey="action_done" />
              </DoneButton>
            </div>
          }
        >
          {!isPageEditor && (
            <InputModalTitle
              value={title}
              placeholder={t('add_an_update_title_optional')}
              onChange={setTitle}
            />
          )}

          {isUploading ? (
            <VideoStreamingUploadingProgress
              progress={progress}
              error={error}
              startUpload={retryUpload}
            />
          ) : (
            <VideoStreaming
              boardId={boardId}
              folderId={folderId}
              nodeId={node?.id || currentNode.id}
            />
          )}
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default EditVideoStreaming
