import { useCallback, useState } from 'react'

import { VideoMetaData } from '@hooks/useUploadFileMetadata'
import useUploadFirebase from '@hooks/useUploadFirebase'
import useValidateVideoStreamingFile from '@features/nodeEditor/components/VideoStreaming/useValidateVideoStreamingFile'

interface Props {
  onUploaded: (videoUrl: string, videoMetadata: VideoMetaData) => void
}

const useVideoStreamingFileUpload = ({ onUploaded }: Props) => {
  const validateVideoStreamingFile = useValidateVideoStreamingFile()
  const [file, setFile] = useState<File | null>(null)
  const { error, progress, uploadFile, cancelUpload } = useUploadFirebase()
  const [isUploading, setIsUploading] = useState(false)
  const [videoMetadata, setVideoMetadata] = useState<VideoMetaData | undefined>(
    undefined,
  )

  const startUpload = useCallback(
    (fileToUpload: File, metadata: VideoMetaData) => {
      if (!fileToUpload || !metadata) return

      setIsUploading(true)

      uploadFile(fileToUpload, (videoUrl: string) => {
        setIsUploading(false)
        onUploaded(videoUrl, metadata)
      })
    },
    [uploadFile, onUploaded],
  )

  const retryUpload = () => {
    if (file && videoMetadata) {
      startUpload(file, videoMetadata)
    }
  }

  const handleFilePicked = useCallback(
    async (newFile: File) => {
      const metadata = await validateVideoStreamingFile(newFile)
      if (metadata) {
        setFile(newFile)
        setVideoMetadata(metadata)
        startUpload(newFile, metadata)
      }
    },
    [setFile, setVideoMetadata, startUpload, validateVideoStreamingFile],
  )

  return {
    error,
    isUploading,
    progress,
    retryUpload,
    cancelUpload,
    handleFilePicked,
  }
}

export default useVideoStreamingFileUpload
