import { useTranslation } from 'react-i18next'
import { MenuItem, useDialog, UploadIcon, GridIcon } from 'boards-web-ui'

import { SourcePickerSingleSelectionModal } from '@features/pickers/sourcePicker/SourcePickerSingleSelectionModal'
import { IconWrapper } from '@ui/components/IconWrapper'
import ModalMenu from '@ui/components/ModalMenu'
import { Node, NodeType } from 'app/models'

interface Props {
  fileType: NodeType
  enabledNodeTypes: NodeType[]
  onUploadFileClick: () => void
  onNodePicked: (onde: Node) => void
}
const useFilePicker = ({
  fileType,
  enabledNodeTypes,
  onUploadFileClick,
  onNodePicked,
}: Props) => {
  const { open, close } = useDialog()
  const { t } = useTranslation()

  const dialogCategory: Partial<Record<NodeType, string>> = {
    [NodeType.IMAGE]: t('destination_picker_copy_image'),
    [NodeType.VIDEO_STREAMING]: t('destination_picker_copy_video'),
    [NodeType.PDF]: t('destination_picker_copy_pdf'),
  }

  const dialogPickerTitle: Partial<Record<NodeType, string>> = {
    [NodeType.IMAGE]: t('image_selector_from_pc_image'),
    [NodeType.VIDEO_STREAMING]: t('image_selector_from_pc_video'),
    [NodeType.PDF]: t('image_selector_from_pc_pdf'),
  }

  const openSourcePicker = () => {
    close()

    open(
      <SourcePickerSingleSelectionModal
        dialogCategory={dialogCategory[fileType] || ''}
        enabledNodeTypes={enabledNodeTypes}
        onClose={close}
        action={onNodePicked}
      />,
      {
        fullScreen: true,
        closeOnOverlayClick: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        withFullScreenDialogContainer: false,
        withoutAnimation: true,
      },
    )
  }

  const handleFromGalleryClick = () => {
    onUploadFileClick()
    close()
  }

  const openFilePicker = () => {
    open(
      <ModalMenu
        items={[
          {
            id: 'grid',
            title: t('image_selector_from_boards'),
            icon: <GridIcon />,
            onClick: openSourcePicker,
          },
          {
            id: 'upload',
            title: t('image_selector_from_pc'),
            icon: <UploadIcon />,
            onClick: handleFromGalleryClick,
          },
        ]}
        title={dialogPickerTitle[fileType] || ''}
        renderItem={(field) => (
          <MenuItem
            style={{
              cursor: 'pointer',
              columnGap: '10px',
              width: '100%',
            }}
            key={field.id}
            onClick={field.onClick}
          >
            <IconWrapper>{field.icon}</IconWrapper>
            {field.title}
          </MenuItem>
        )}
      />,
    )
  }

  return openFilePicker
}

export default useFilePicker
