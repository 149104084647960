import React, { FC, PropsWithChildren } from 'react'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import { BoardListItem } from '@features/boardsMenu/components/BoardListItem'

import { ReactComponent as VectorArrow } from '../../../../icons/VectorArrow.svg'

import styles from './MenuTitle.module.css'

type Props = PropsWithChildren<{
  inactive?: boolean
  boardId: string
  nodeId: string
  level: number
  title: string
  icon: string
  isExpanded: boolean
  isSelected: boolean
  isDisabled: boolean
  isSponsored: boolean
  hasSharedIcon: boolean
  onClick: () => void
  onToggle?: () => void
  className?: string
  classNameTitle?: string
  isPageBoard?: boolean
  isFreeUserMenu?: boolean
  countBoardMembers: number
  roleText: React.ReactNode
}>

const MenuTitle: FC<Props> = ({
  inactive,
  boardId,
  nodeId,
  level,
  title,
  icon,
  isExpanded,
  isSponsored,
  isSelected,
  isDisabled,
  hasSharedIcon,
  onClick,
  onToggle,
  children,
  className,
  isPageBoard,
  countBoardMembers,
  roleText,
  isFreeUserMenu,
  classNameTitle,
}) => {
  const { search } = useLocation()

  const onToggleClick = (e: {
    stopPropagation: () => void
    preventDefault: () => void
  }) => {
    e.preventDefault()

    if (onToggle && !isDisabled) {
      onToggle()
    }
  }

  const titleClass = level === 1 ? styles.BigTitle : ''

  const marginLeftValueShift = isFreeUserMenu ? 1 : 0

  return (
    <Link
      to={`/${boardId}/${nodeId}${search}`}
      className={clsx(
        styles.Root,
        inactive && !isPageBoard && styles.Inactive,
        isSelected && styles.Selected,
        isDisabled && styles.Disabled,
        classNameTitle,
      )}
      onDoubleClick={onToggleClick}
      onClick={onClick}
    >
      <button
        name="toggle"
        aria-label="Expand or collapse Menu"
        style={{ marginLeft: `${(level - marginLeftValueShift) * 20}px` }}
        className={clsx(styles.Toggle, className)}
        onClick={onToggleClick}
        disabled={isDisabled}
      >
        <VectorArrow
          className={clsx(
            styles.ToggleIcon,
            isExpanded && !isDisabled && styles.Expanded,
          )}
        />
      </button>

      <BoardListItem
        title={title}
        icon={icon}
        className={className}
        isPageBoard={isPageBoard}
        hasSharedIcon={hasSharedIcon}
        roleText={roleText}
        titleClass={titleClass}
        isSponsored={isSponsored}
        countBoardMembers={countBoardMembers}
      />

      {children}
    </Link>
  )
}

export default React.memo(MenuTitle)
