import { Trans, useTranslation } from 'react-i18next'
import { AnimatedDialog, useDialog } from 'boards-web-ui'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import CloseButtonWithTooltip from '@ui/components/CloseButtonWithTooltip'
import DoneButton from '@ui/components/DoneButton'
import VideoStreaming from '@features/nodeEditor/components/VideoStreaming'
import { useSelectorFactory } from '@hooks/useSelectorFactory'
import useRouterParams from '@hooks/useRouterParams'

import { Node, NodeType } from '../../../../models'
import { videoActions } from '../../../../../actions'
import ModalEditWithConfirmation from '../../../../../components/ModalEditWithConfirmation'
import InputModalTitle from '../../../../../elements/InputModalTitle'
import { getCurrentFolderId } from '../../../../../selectors'

import useNodeAPI from '../../hooks/useNodeAPI'
import useNodeDiscardChanges from '../../hooks/useNodeDiscardChanges'

import { INodeProps } from '../../models/IUseNodeCreatorAPI'

import styles from './AddVideoStreaming.module.css'

interface Props {
  node: Node & { type: NodeType.VIDEO_STREAMING }
  isPageEditor?: boolean
  onUpdate: (id: string, node: INodeProps) => void
}
const AddVideoStreamingNode = ({ node, isPageEditor, onUpdate }: Props) => {
  const { close } = useDialog()
  const dispatch = useDispatch()
  const discardChanges = useNodeDiscardChanges()
  const { t } = useTranslation()
  const { title, setTitle } = useNodeAPI('', '')
  const { boardId } = useRouterParams()
  const currentFolderId = useSelectorFactory(getCurrentFolderId)

  const handleSave = () => {
    if (!node) return

    onUpdate(node?.id, {
      title,
      text: node?.text,
      type: NodeType.VIDEO_STREAMING,
    })
    dispatch(videoActions.setVideoStreamingId(''))
    dispatch(videoActions.setVideoStreamingUrl(''))
    close()
  }

  const handleClose = () => {
    discardChanges()
  }

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        onEscape: handleClose,
        onOverlayClick: handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            <DoneButton onClick={handleSave}>
              <Trans i18nKey="action_done" />
            </DoneButton>
          }
        >
          <div className={clsx(styles.Root, !isPageEditor && styles.WithTitle)}>
            {!isPageEditor && (
              <InputModalTitle
                value={title}
                placeholder={t('add_an_update_title_optional')}
                onChange={setTitle}
              />
            )}
            {node && (
              <VideoStreaming
                boardId={boardId}
                folderId={currentFolderId}
                nodeId={node.id}
                showDescriptionLabel
              />
            )}
          </div>
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default AddVideoStreamingNode
