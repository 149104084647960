import React, { memo, useRef } from 'react'

import clsx from 'clsx'
import { useScrollAnchor } from '../../../../ui/hooks/useScrollAnchor'
import { useUpdatesMessages } from '../hooks/useUpdatesMessages'

import useUserFeatures, { Features } from '../../../../hooks/useUserFeatures'

import UpdatesCreateMessage from './UpdatesCreateMessage'
import UpdatesSelected from './UpdatesSelected'
import UpdatesMessages from './UpdatesMessages'
import UpdatesMessageLimits from './UpdatesMessageLimits'
import styles from './UpdatesScrollArea.module.css'

const UpdatesScrollArea: React.FC = () => {
  const { selectedMessage, viewOnly } = useUpdatesMessages()
  const { tryFree } = useUserFeatures(Features.messages)
  const scrollableElementRef = useRef<HTMLDivElement>(null)
  const createMessageRef = useRef<HTMLDivElement>(null)

  const { rememberAnchor, scrollToAnchor, checkBottomPosition } =
    useScrollAnchor(scrollableElementRef, createMessageRef)

  return (
    <>
      {tryFree && <UpdatesMessageLimits />}

      <div className={styles.Scrollable} ref={scrollableElementRef}>
        <div className={selectedMessage ? styles.Hidden : styles.Visible}>
          {!viewOnly && (
            <UpdatesCreateMessage
              disabled={viewOnly}
              onChange={checkBottomPosition}
              ref={createMessageRef}
            />
          )}

          <UpdatesMessages
            onSelectMessage={rememberAnchor}
            className={clsx(viewOnly && styles.ViewOnly)}
          />
        </div>

        <UpdatesSelected onClose={scrollToAnchor} onDelete={scrollToAnchor} />
      </div>
    </>
  )
}

export default memo(UpdatesScrollArea)
