import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  AnimatedDialog,
  GrabIcon,
  PageNodeProfileVariants,
  useDialog,
} from 'boards-web-ui'

import PageProfileDialogFooter from '@features/PageProfile/components/PageProfileDialogFooter'
import PageProfileDialogHeader from '@features/PageProfile/components/PageProfileDialogHeader'
import CropImageTool from '@features/PageProfile/components/CropImageTool'
import useNodeDiscardChanges from '@features/nodeEditor/hooks/useNodeDiscardChanges'
import { cropAndResizeImage } from '@features/PageProfile/utils/imageUtils'

import { ModalEditWithConfirmationAndTitle } from 'components/ModalEditWithConfirmation'

import styles from './CropImageDialog.module.css'

export interface CropSettingsProps {
  zooming: number
  positionX: number
  positionY: number
  height: number
  width: number
}

type CropImageDialogProps = {
  variation: PageNodeProfileVariants
  imageSrc: string
  imageBuffer: ArrayBuffer
  imageType: string
  imageWidth: number
  cropImage: (imageBufferPromise: Promise<Buffer>, imageType: string) => void
  onCancel?: () => void
}
const CropImageDialog = ({
  variation,
  imageSrc,
  imageBuffer,
  imageType,
  imageWidth,
  cropImage,
  onCancel,
}: CropImageDialogProps) => {
  const { t } = useTranslation()
  const { close } = useDialog()
  const discardChanges = useNodeDiscardChanges()
  const [cropSettings, setCropSettings] = useState<
    CropSettingsProps | undefined
  >()

  const crop = async () => {
    if (cropSettings) {
      cropImage(
        cropAndResizeImage(imageBuffer, {
          x: cropSettings.positionX,
          y: cropSettings.positionY,
          width: cropSettings.width,
          height: cropSettings.height,
        }),
        imageType,
      )

      close()
    }
  }

  const onCancelHandler = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
    return discardChanges(() => {}, {
      title: t('profile_discard_image'),
      message: t('profile_confirmation_text'),
    })
  }

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
      }}
      dialog={
        <ModalEditWithConfirmationAndTitle
          responsiveHeight
          withBorders
          withFixedBody
          className={styles.Root}
          headerControls={
            <PageProfileDialogHeader
              title={t('profile_image_adjustment_title')}
              onCancel={onCancelHandler}
            />
          }
          footerControls={
            <PageProfileDialogFooter
              onSubmit={cropSettings ? crop : undefined}
              leftElement={
                <>
                  <GrabIcon />
                  <span className={styles.DragText}>
                    <Trans i18nKey="profile_drag_image" />
                  </span>
                </>
              }
              name={t('action_done')}
            />
          }
        >
          <div className={styles.Body}>
            <CropImageTool
              variation={variation}
              imageSrc={imageSrc}
              imageWidth={imageWidth}
              setCropSettings={setCropSettings}
            />
          </div>
        </ModalEditWithConfirmationAndTitle>
      }
    ></AnimatedDialog>
  )
}

export default CropImageDialog
