import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import useAddVideoStreamingDialogs from '@features/nodeEditor/hooks/useAddVideoStreamingDialogs'
import useVideoPicker from '@features/pickers/hooks/useVideoPicker'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import usePaywall from '@hooks/usePaywall'
import useUserFeatures, { Features } from '@hooks/useUserFeatures'
import { NodeType, Node } from 'app/models'

interface Props {
  onFilePicked: (file: File) => void
  onNodePicked: (node: Node & { type: NodeType.VIDEO_STREAMING }) => void
}
const useOpenVideoStreamingPicker = ({ onFilePicked, onNodePicked }: Props) => {
  const { openPaywall } = usePaywall()
  const { limitReachedDialog } = useAddVideoStreamingDialogs()
  const { tryFree, enabled, limitReached } = useUserFeatures(
    Features.uploadVideo,
  )
  const {
    profile: { usage, plan },
  } = useProfileInformation()

  const openFilePicker = useVideoPicker({
    onFilePicked,
    onNodePicked,
  })

  const openVideoPicker = () => {
    if (!enabled || (tryFree && limitReached)) {
      openPaywall({ source: PaywallSourceEnum.VIDEO_LIMIT_REACHED })
      return
    }

    if (limitReached) {
      limitReachedDialog(usage.videos, plan.limits.videos)
      return
    }

    openFilePicker()
  }

  return openVideoPicker
}

export default useOpenVideoStreamingPicker
