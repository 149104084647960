import useFileOrNodePicker from '@features/pickers/hooks/useFileOrNodePicker'
import { NodeType, Node } from 'app/models'

interface Props {
  onFilePicked: (file: File) => void
  onNodePicked: (node: Node & { type: NodeType.VIDEO_STREAMING }) => void
}
const useVideoPicker = ({ onFilePicked, onNodePicked }: Props) =>
  useFileOrNodePicker<Node & { type: NodeType.VIDEO_STREAMING }, false>({
    acceptTypes: '.mov, .mp4',
    enabledNodeTypes: [
      NodeType.VIDEO_STREAMING,
      NodeType.FOLDER,
      NodeType.PAGE,
    ],
    fileType: NodeType.VIDEO_STREAMING,
    multiple: false,
    onFilePicked,
    onNodePicked,
  })

export default useVideoPicker
