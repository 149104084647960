import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { useToast } from '@hooks/useToast'
import { newKey } from '@helpers/NodeIdGenerator'

import useBoard from '@hooks/useBoard'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'
import { useUpdatesEvents } from '../../analytics/useUpdatesEvents'
import { useUpdatesTranslations } from './useUpdatesTranslations'

import {
  MessageProperties,
  UiUpdatesMessage,
  UpdatesMessages,
} from '../../../models'
import { updatesActions } from '../../../../actions'
import useRouterParams from '../../../../hooks/useRouterParams'
import { QUERY_PARAMS } from '../../../constants/routes'

type UpdatesApiState = {
  postMessage: (properties: MessageProperties & { senderName?: string }) => void
  selectMessage: (message: UiUpdatesMessage | null) => void
  removeMessage: (message: UiUpdatesMessage) => void
  markAsRead: (message: UiUpdatesMessage) => void
  marksAllAsRead: (messages: UpdatesMessages) => void
}

export const useUpdatesApi = (): UpdatesApiState => {
  const dispatch = useDispatch()
  const { newMessageSend, messageDeleted, sentToastShown } = useUpdatesEvents()
  const { toastDeleted } = useUpdatesTranslations()
  const { boardId } = useRouterParams()
  const [searchParams, setSearchParams] = useSearchParams({})
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const toast = useToast()
  const { permissions } = useBoard()

  const postMessage = (
    propertiesData: MessageProperties & { senderName?: string },
  ) => {
    if (!isApiAvailableOrNotify()) return

    const { senderName, ...properties } = propertiesData
    const key = newKey()

    dispatch(
      updatesActions.postUpdatesMessage({
        board: boardId,
        key,
        properties,
        senderName,
      }),
    )
    newMessageSend({
      boardId: boardId || '0',
      value: key,
      title: properties.title,
      body: properties.text,
      membersCount: permissions.length,
    })
    sentToastShown()
  }

  const removeMessage = (message: UiUpdatesMessage) => {
    if (!isApiAvailableOrNotify()) return

    dispatch(updatesActions.deleteUpdatesMessage(boardId, message.id))
    setSearchParams({}, { replace: true })
    messageDeleted(message.id)

    toast(toastDeleted(), true, 300)
  }

  const markAsRead = (message: UiUpdatesMessage) => {
    if (message.unread) {
      dispatch(
        updatesActions.markUpdatesMessagesAsRead({
          board: boardId,
          messages: [message.id],
        }),
      )
    }
  }

  const marksAllAsRead = (messages: UpdatesMessages) => {
    const messageIds = messages.filter((msg) => msg.unread).map((msg) => msg.id)

    if (!messageIds.length) {
      return
    }

    dispatch(
      updatesActions.markUpdatesMessagesAsRead({
        board: boardId,
        messages: messageIds,
      }),
    )
  }

  const selectMessage = (message: UiUpdatesMessage | null) => {
    if (message) {
      searchParams.set(QUERY_PARAMS.message, message.id)
    } else {
      searchParams.delete(QUERY_PARAMS.message)
    }
    setSearchParams(searchParams, { replace: true })
  }

  return {
    postMessage,
    markAsRead,
    marksAllAsRead,
    selectMessage,
    removeMessage,
  } as const
}
