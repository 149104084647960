import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { If, ImagePicker, InputField, parseUrl, Spacer } from 'boards-web-ui'

import { NodeType, Node } from 'app/models'
import usePageProfile from '@features/PageProfile/hooks/usePageProfile'
import useImagePicker from '@features/pickers/hooks/useImagePicker'

import { createFileFromUrl } from 'utils/createFileFromUrl'
import ProfileVariationPicker from './ProfileVariationPicker'

import styles from './PageProfileForm.module.css'

const PageProfileForm: FC = () => {
  const { t } = useTranslation()
  const {
    pageProfileFormState: {
      onChange,
      uploadImage,
      formState,
      pending,
      cropping,
      imagePreview,
    },
    isProfileVariationAvailable,
  } = usePageProfile()

  const addImageNode = async (node: Node & { type: NodeType.IMAGE }) => {
    const { pathname } = parseUrl(node.text)

    const imageFile = await createFileFromUrl(
      node.text,
      pathname.replace('/', ''),
    )
    uploadImage(imageFile)
  }

  const openImagePicker = useImagePicker({
    multiple: false,
    onFilePicked: uploadImage,
    onNodePicked: addImageNode,
  })

  return (
    <>
      <ImagePicker
        accept="image/png, image/jpeg"
        pending={pending || cropping}
        label={t('profile_cover_image')}
        emptyLabel={t('profile_add_an_image')}
        imageSrc={imagePreview || undefined}
        onClick={openImagePicker}
      />

      <Spacer height={15} />

      <InputField
        withResetButton
        name="name"
        label={t('profile_headline')}
        placeholder={t('profile_headline_placeholder')}
        value={formState.profile.name}
        onChange={onChange}
        className={styles.Overflow}
      />
      <Spacer height={15} />

      <InputField
        withResetButton
        name="title"
        label={t('profile_description')}
        placeholder={t('profile_description_placeholder')}
        value={formState.profile.title}
        onChange={onChange}
        className={styles.Overflow}
      />
      <Spacer height={15} />

      <InputField
        withResetButton
        name="company"
        label={t('profile_additional_information')}
        placeholder={t('profile_additional_information_placeholder')}
        value={formState.profile.company}
        onChange={onChange}
        className={styles.Overflow}
      />
      <Spacer height={15} />

      <If state={isProfileVariationAvailable}>
        <ProfileVariationPicker
          label="Variation"
          name="variation"
          value={formState.profile.variation}
          onChange={onChange}
        />
      </If>
    </>
  )
}

export default PageProfileForm
