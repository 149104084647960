import { useDialog, useUpload } from 'boards-web-ui'

import { Node, NodeType } from 'app/models'
import useFilePicker from '@features/pickers/hooks/useFilePicker'

export type FilePickerHandler<Multiple extends boolean> = (
  file: Multiple extends false ? File : FileList,
) => void

interface UseFilePickerConfig<T extends Node, Multiple extends boolean> {
  acceptTypes: string
  enabledNodeTypes: NodeType[]
  fileType: T['type']
  multiple: Multiple
  onFilePicked: FilePickerHandler<Multiple>
  onNodePicked?: (node: T) => void
}
const useFileOrNodePicker = <T extends Node, Multiple extends boolean>({
  acceptTypes,
  enabledNodeTypes,
  fileType,
  multiple,
  onFilePicked,
  onNodePicked,
}: UseFilePickerConfig<T, Multiple>) => {
  const { close } = useDialog()

  const handleNodePicked = (node: Node) => {
    if (onNodePicked && node.type === fileType) {
      close()
      onNodePicked(node as T)
    }
  }

  const uploadFile = useUpload(onFilePicked, {
    accept: acceptTypes,
    multiple,
  })

  const openFilePicker = useFilePicker({
    enabledNodeTypes,
    fileType,
    onNodePicked: handleNodePicked,
    onUploadFileClick: uploadFile,
  })

  const openFileOrNodePicker = () => {
    if (onNodePicked) {
      openFilePicker()
      return
    }

    uploadFile()
  }

  return openFileOrNodePicker
}

export default useFileOrNodePicker
