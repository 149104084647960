import useFileOrNodePicker from '@features/pickers/hooks/useFileOrNodePicker'
import { NodeType, Node } from 'app/models'

interface Props {
  onFilePicked: (file: File) => void
  onNodePicked: (node: Node & { type: NodeType.PDF }) => void
}
export const usePdfPicker = ({ onFilePicked, onNodePicked }: Props) =>
  useFileOrNodePicker<Node & { type: NodeType.PDF }, false>({
    acceptTypes: 'application/pdf',
    enabledNodeTypes: [NodeType.PDF, NodeType.FOLDER, NodeType.PAGE],
    fileType: NodeType.PDF,
    multiple: false,
    onFilePicked,
    onNodePicked,
  })
