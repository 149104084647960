import { FC, useEffect } from 'react'
import BoardsSelection from '@features/pickers/components/BoardsSelection'
import FolderSelection from '@features/pickers/components/FolderSelection'
import { ContentPickersEnum, useContentNavigation } from '@features/pickers'
import PageSelection from '@features/pickers/components/PageSelection'
import useFirebaseListeners from '../../../../../_firebase/hooks/useFirebaseListeners'
import { Node as NodeModel, NodeType } from '../../../../models'

interface Props {
  nodes: NodeModel[]
  withRadioBtn?: boolean
  enabledNodeTypes: NodeType[]
  toggleNode: (node: NodeModel) => void
  beforeSelectFolder?: (callback: () => void) => void
}

export const SelectContentDialogBody: FC<Props> = ({
  nodes,
  withRadioBtn,
  enabledNodeTypes,
  toggleNode,
  beforeSelectFolder,
}) => {
  const {
    pickerBoardId: boardId,
    pickerFolderId: folderId,
    isPage,
    selectBoard,
    selectFolder,
  } = useContentNavigation(ContentPickersEnum.SourcePicker)

  const { startBoardFoldersListener } = useFirebaseListeners()

  useEffect(() => {
    if (boardId) {
      const [uid, bid] = boardId.split('-')
      startBoardFoldersListener(uid, bid)
    }
  }, [boardId, startBoardFoldersListener])

  const handleSelectFolder = (fid: string) => {
    if (beforeSelectFolder) {
      beforeSelectFolder(() => selectFolder(fid))
    } else {
      selectFolder(fid)
    }
  }

  return (
    <>
      {!boardId && <BoardsSelection selectBoard={selectBoard} />}

      {boardId && folderId && !isPage && (
        <FolderSelection
          nodes={nodes}
          boardId={boardId}
          folderId={folderId}
          selectFolder={handleSelectFolder}
          toggleNode={toggleNode}
          picker={ContentPickersEnum.SourcePicker}
          isNodeTypeDisabled={(type: NodeType) =>
            !enabledNodeTypes.includes(type)
          }
          withRadioBtn={withRadioBtn}
        />
      )}

      {boardId && folderId && isPage && (
        <PageSelection
          nodes={nodes}
          boardId={boardId}
          folderId={folderId}
          toggleNode={toggleNode}
          isNodeTypeDisabled={(type: NodeType) =>
            !enabledNodeTypes.includes(type)
          }
          withRadioBtn={withRadioBtn}
        />
      )}
    </>
  )
}
