import React, { createContext, useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useSelectorFactory } from '@hooks/useSelectorFactory'
import { useUpdatesEvents } from '../analytics/useUpdatesEvents'

import { getIsLoading, selectBoardsCount } from '../../../selectors'

import { QUERY_PARAMS } from '../../constants/routes'

export type UpdatesToolsState = {
  isOpen: boolean
  isAvailable: boolean
  disabled: boolean
  close: () => void
  toggle: () => void
}

const defaultState: UpdatesToolsState = {
  isOpen: false,
  isAvailable: false,
  disabled: false,
  /* eslint-disable */
  close: () => {},
  toggle: () => {},
  /* eslint-enable */
}

export const UpdatesContext = createContext<UpdatesToolsState>({
  ...defaultState,
})

const UpdatesProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { updatesPaneClose } = useUpdatesEvents()
  const [searchParams, setSearchParams] = useSearchParams()
  const loading = useSelectorFactory(getIsLoading)
  const boardsCount = useSelectorFactory(selectBoardsCount)
  const isOpen = useMemo(
    () => searchParams.has(QUERY_PARAMS.showMessage),
    [searchParams],
  )

  const open = useCallback(() => {
    setSearchParams(
      (prevParams) =>
        new URLSearchParams({
          ...Object.fromEntries(prevParams.entries()),
          [QUERY_PARAMS.showMessage]: 'true',
        }),
    )
  }, [setSearchParams])

  const close = useCallback(() => {
    updatesPaneClose()
    setSearchParams((prevParams) => {
      prevParams.delete(QUERY_PARAMS.showMessage)
      return searchParams
    })
  }, [updatesPaneClose, searchParams, setSearchParams])

  const toggle = useCallback(
    () => (isOpen ? close() : open()),
    [isOpen, open, close],
  )

  const data: UpdatesToolsState = useMemo(
    () => ({
      isAvailable: !loading && !!boardsCount,
      disabled: false,
      isOpen,
      close,
      toggle,
    }),
    [loading, isOpen, boardsCount, toggle, close],
  )

  return (
    <UpdatesContext.Provider value={data}>{children}</UpdatesContext.Provider>
  )
}

export default UpdatesProvider
