import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useTemplatePickerEvents from '@features/analytics/useTemplatePickerEvents'
import useRouterParams from '@hooks/useRouterParams'
import useShortcut from '@hooks/useShortcut'
import { Folder } from 'app/models'

const useTipBanner = (folder: Folder) => {
  const { getShortcut } = useShortcut()
  const { boardId, folderId } = useRouterParams()
  const location = useLocation()
  const navigate = useNavigate();
  const { pagePersonalizeTemplateDismissEvent } = useTemplatePickerEvents()

  return useMemo(() => {
    return {
      isTipBannerVisible: location.state?.isCopiedFromTemplate,
      handleTemplateBannerDismiss: () => {
        pagePersonalizeTemplateDismissEvent({
          boardId,
          nodeId: folderId,
          pageId: folderId,
          linkShortcut: getShortcut(folder) || '',
          templateCategory: location.state?.templateCategory,
          templateName: location.state?.templateName,
        })
        const locationState = location.state || {}
        delete locationState.isCopiedFromTemplate;
        navigate('.', { replace: true, state: locationState })
      },
    }  as const
  }, [
    navigate,
    location.state,
    boardId,
    folderId,
    getShortcut,
    folder,
    pagePersonalizeTemplateDismissEvent,
  ])
}

export default useTipBanner
