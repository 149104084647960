import { useState } from 'react'
import { AnimatedDialog, NODE_LINK_TYPES, useDialog } from 'boards-web-ui'
import { useDispatch } from 'react-redux'

import styles from '@features/nodeEditor/components/EditButton.module.css'
import EditButtonHeader from '@features/nodeEditor/components/EditButtonHeader'
import useButtonTranslations from '../../contactButtons/hooks/useButtonTranslations'
import useGeoLocation from '../../../../hooks/useGeoLocation'
import { NodeType, Node } from '../../../models'

import { ICreateOrUpdateNode } from '../models/IUseNodeCreatorAPI'
import { buildText } from '../../contactButtons/util'

import { ModalEditWithConfirmationAndTitle } from '../../../../components/ModalEditWithConfirmation'
import ButtonPicker from '../../contactButtons/ButtonPicker'
import EditButton from './EditButton'

import { buttonActions } from '../../../../actions'

interface Props {
  onSave: ICreateOrUpdateNode
}
const AddButton = ({ onSave }: Props) => {
  const [node, setNode] = useState<Node | null>(null)
  const dispatch = useDispatch()
  const { close } = useDialog()
  const { getButtonTranslations } = useButtonTranslations()
  const { geoData } = useGeoLocation()

  const handleButtonPick = (type: NODE_LINK_TYPES) => {
    const buttonTranslations = getButtonTranslations(type)
    const text = type === NODE_LINK_TYPES.FACEBOOK ? buildText(type, '') : ''

    setNode({
      type: NodeType.BUTTON,
      link_type: type,
      text,
      title:
        type === NODE_LINK_TYPES.REGULAR
          ? ''
          : buttonTranslations.autoPopulateText,
      countryCode: geoData?.countryCode || 'us',
      diallingCode: geoData?.dialCode || 1,
    } as Node)
  }

  const closeModal = () => {
    close()
    // TODO reset state action
    dispatch(buttonActions.setButtonType(undefined))
    dispatch(buttonActions.setButtonError(undefined))
    dispatch(buttonActions.setButtonGeoData(undefined))
  }

  if (!node) {
    return (
      <AnimatedDialog
        index={2}
        settings={{
          fullScreen: true,
          blankDialog: true,
          closeOnOverlayClick: false,
          hideParents: true,
          withoutAnimation: true,
          overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
          onEscape: close,
          onOverlayClick: close,
        }}
        dialog={
          <ModalEditWithConfirmationAndTitle
            className={styles.ModalSize}
            withBorders
            withFixedBody
            headerControls={
              <EditButtonHeader onClose={closeModal}>
                <h3 className={styles.HeaderTitle}>Buttons</h3>
              </EditButtonHeader>
            }
          >
            <ButtonPicker onSelectButton={handleButtonPick} />
          </ModalEditWithConfirmationAndTitle>
        }
      />
    )
  }

  return (
    <EditButton node={node} onSave={onSave} onCancel={() => setNode(null)} />
  )
}

export default AddButton
