import { extractBoardId } from '@helpers/extractBoardId'
import {
  getBoardIdAndOwner,
  getCLeanBoardAndOwnerIds,
  getRootFolderId,
} from '@helpers/NodeIdGenerator'
import { getTimeFromNanoSeconds } from '@helpers/getTimeFromNanoSeconds'
import { convertFirestoreTimestampToDate } from '@hooks/useCountdownTimer'

import { mergeMessageWithBoard } from '../utils/mergeMessageWithBoard'
import findMissedFieldsInPgeForms from '../utils/findMissedFieldsInForm'
import {
  Folder,
  NodeType,
  Permission,
  Role,
  UiUpdatesMessage,
  UpdatesMessage,
  UpdatesMessages,
} from '../app/models'
import { getNode } from '../reducers/FolderManagement'
import { AppState } from '../app/models/appState'

export function selectIntegrationState(
  state: AppState,
  boardId: string,
  pageId: string,
  nodeId: string,
) {
  const node = getNode(state.folders, boardId, pageId, nodeId)
  return node?.integrationState || undefined
}

export function selectMissedPageFields(
  state: AppState,
  boardId: string,
  pageId: string,
  isCheckoutFeatureAvailable: boolean,
) {
  const page = getFolder(state, boardId, pageId)

  if (page && page.isPage) {
    return findMissedFieldsInPgeForms({
      page,
      isCheckoutFeatureAvailable,
    })
  }

  return {}
}

export function getParentFolder(
  state: AppState,
  boardId: string,
  nodeId: string,
) {
  if (!boardId) {
    return null
  }

  function findParent(
    folders: { [fid: string]: Folder },
    folderId: string,
  ): Folder | null {
    const folder = folders[folderId]

    if (folder) {
      const item = folder.content.find((f) => f.id === nodeId)

      if (item) {
        return folder
      }

      // eslint-disable-next-line
      for (const node of folder.content) {
        if (node.type === 'folder') {
          const parentId = findParent(folders, node.id)
          if (parentId) {
            return parentId
          }
        }
      }
    }

    return null
  }

  const folders = state?.folders[boardId]
  const rootFolderId = getRootFolderId(boardId)

  if (rootFolderId === nodeId) {
    return rootFolderId
  }
  if (!folders) {
    return null
  }

  return findParent(folders, rootFolderId)
}

export function getBoard(state: AppState, boardId: string) {
  return state.boards[boardId]
}

export function getIsBoardActive(state: AppState, boardId: string) {
  return (
    state?.profileInfo?.plan?.limits?.boards === 'unlimited' ||
    state?.profileInfo?.activeBoards?.includes(boardId)
  )
}

export function unReadMessage(
  state: AppState,
  boardId: string,
  message: UpdatesMessage,
) {
  const currentData = state.updatesReadUnRead[boardId]

  if (!currentData) {
    return false
  }

  const messageDate = getTimeFromNanoSeconds(
    message.time.seconds,
    message.time.nanoseconds,
  )
  const readDate = getTimeFromNanoSeconds(
    currentData.after.seconds,
    currentData.after.nanoseconds,
  )

  if (messageDate > readDate) {
    return true
  }

  return currentData.messages.includes(message.id)
}

export function updatesMessages(state: AppState) {
  let messages: UpdatesMessages = []

  Object.keys(state.updates).forEach((key) => {
    const boardMessages = state.updates[key]
    if (!boardMessages.length) {
      return
    }

    const boardId = key
    const board = getBoard(state, boardId)

    messages = [
      ...messages,
      ...boardMessages.map((msg) =>
        board
          ? mergeMessageWithBoard(
              msg,
              board,
              unReadMessage(state, boardId, msg),
            )
          : msg,
      ),
    ] as UpdatesMessages
  })

  return messages
}

export function updatesMessagesByBoardId(state: AppState, boardId: string) {
  const messages = updatesMessages(state)

  return messages
    .filter((message) => message.node === extractBoardId(boardId))
    .sort((a, b) => {
      if (a.time.seconds > b.time.seconds) {
        return -1
      }
      if (a.time.seconds < b.time.seconds) {
        return 1
      }
      return 0
    })
}

export function updatesUnReadMessagesByBoardId(
  state: AppState,
  boardId: string,
) {
  const messages = updatesMessagesByBoardId(state, boardId)

  return messages.filter((message) => message.unread)
}

export function updatesMessageById(state: AppState, messageId: string) {
  if (!messageId) {
    return null
  }

  const messages = updatesMessages(state)
  return messages.find((msg) => msg.id === messageId) || null
}

export function updatesMessageCount(
  state: AppState,
  message: UiUpdatesMessage | null,
) {
  if (!message) {
    return null
  }

  const boardMessages = updatesMessagesByBoardId(
    state,
    `${message.sender}-${message.node}`,
  )

  if (!boardMessages.length || boardMessages.length === 1) {
    return 1
  }

  return boardMessages.findIndex((msg) => msg.id === message.id) + 1
}

export function updatesSavedMessage(state: AppState, boardId: string) {
  return state.updatesUnPostMessages[boardId] || { title: '', text: '' }
}

export function selectProfileInformation(
  state: AppState,
): AppState['profileInfo'] & { loaded: boolean } {
  return {
    ...state.profileInfo,
    loaded: state.profileInfo.profileIsLoaded,
  }
}

export function getFolder(
  state: AppState,
  boardId?: string,
  folderId?: string,
) {
  if (!boardId) {
    return undefined
  }

  const folders = state.folders[boardId]
  if (folders && folderId) {
    return folders[folderId]
  }

  return undefined
}

export function getFolders(state: AppState, boardId: string) {
  return state.folders[boardId]
}

export function selectBoards(state: AppState) {
  const {
    boards,
    profileInfo: { plan },
    app: { content },
  } = state
  const boardIds = Object.keys(boards)

  boardIds.forEach((board) => {
    boards[board] = {
      ...boards[board],
      isActive:
        plan?.limits?.boards === 'unlimited'
          ? true
          : state?.profileInfo?.activeBoards.includes(
              getBoardIdAndOwner(board)?.[1] as string,
            ),
    }
  })

  const userOrder = content.order
    .concat(boardIds.filter((item) => content.order.indexOf(item) < 0))
    .filter((contentId) => boardIds?.includes(contentId))
    .sort((boardA) => (boards[boardA].isPageBoard ? -1 : 1))
  const indexOfPagesBoard = userOrder.findIndex(
    (item) => boards[item]?.isPageBoard,
  )

  const orderByActiveField = getBoardsOrderByActive(
    userOrder,
    state?.profileInfo?.activeBoards,
  )

  const order = Array.from(new Set(userOrder))
  const count = order?.length
  const activeBoardsCount =
    order?.filter((boardId) => boards?.[boardId]?.isActive)?.length || 0
  const inactiveBoardsCount = count - activeBoardsCount

  return {
    boards,
    order,
    count,
    activeBoardsCount,
    inactiveBoardsCount,
    orderByActiveField,
    indexOfPagesBoard,
  }
}

export function getBoardsOrderByActive(
  userOrder: string[] = [],
  activeBoards: string[] = [],
) {
  return userOrder.sort((aBoard, bBoard) => {
    const aBid = getCLeanBoardAndOwnerIds(aBoard)?.bid as never
    const bBid = getCLeanBoardAndOwnerIds(bBoard)?.bid as never

    const aIsActive = activeBoards.includes(aBid)
    const bIsActive = activeBoards.includes(bBid)

    if (aIsActive && !bIsActive) {
      return -1
    }

    if (!aIsActive && bIsActive) {
      return 1
    }

    return userOrder.indexOf(aBid) - userOrder.indexOf(bBid)
  })
}

export function getIsLoading(state: AppState) {
  return state.app.loading
}

export function selectBoardsCount(state: AppState) {
  const { boards } = selectBoards(state)

  return Object.keys(boards).length
}

export function getBoardPermissions(state: AppState, boardId: string) {
  return state.permissions[boardId]
}

export function getBoardPermissionsLoaded(state: AppState, boardId: string) {
  const permission = state.permissionsLoaded[boardId]
  if (permission) {
    return permission
  }

  return false
}

export function getBoardRole(
  state: AppState,
  boardId: string,
  email: string | null | undefined,
): Role {
  const board = state.boards[boardId]
  const permissions = state.permissions[boardId]

  if (board && board.isOwn) {
    return Role.OWN
  }

  if (permissions && permissions[email as keyof Permission]) {
    return (permissions[email as keyof Permission] as unknown as Permission)
      .permission
  }

  return Role.READ
}

export function boardBreadcrumbs(
  state: AppState,
  boardId: string,
  folderId: string,
) {
  const board = getBoard(state, boardId)
  const folders = state.folders[boardId]
  if (!board || !folders) {
    return []
  }

  const rootFolder = folders[board.rootFolderId]

  return findPath(folders, rootFolder, folderId, [])
}

const findPath = (
  folders: { [p: string]: Folder },
  folder: Folder,
  id: string,
  path: {
    id: string
    icon: string
    title: string
  }[],
): {
  id: string
  icon: string
  title: string
}[] => {
  if (!Array.isArray(folder?.content)) {
    return []
  }

  const newPath = [...path]
  newPath.push({ id: folder.id, icon: folder.icon, title: folder.title })

  if (id === undefined || String(folder.id) === String(id)) {
    return newPath
  }

  const nodes = folder.content.filter((f) => f.type === NodeType.FOLDER)

  // eslint-disable-next-line
  for (const f of nodes) {
    const ffolder = folders[f.id]

    const result = findPath(folders, ffolder, id, newPath)
    if (result.length > 0) {
      return result
    }
  }

  return []
}

export const selectMaxBoardsMembers = (state: AppState) => {
  return Object.keys(state.boards).reduce((prev, currentValue) => {
    const board = state.boards[currentValue]

    if (board.isOwn && board.summary?.permissions?.total > prev) {
      return state.boards[currentValue].summary.permissions.total
    }

    return prev
  }, 0)
}

export const selectNumberOfSponsoredBoards = (state: AppState) => {
  return Object.keys(state.boards).reduce((prev, currentValue) => {
    if (state.boards[currentValue].options?.notCountedTowardsLimit) {
      return prev + 1
    }

    return prev
  }, 0)
}

export const appLoading = (state: AppState) => state.app.loading

export const getCurrentFolderId = (state: AppState) => state.app.currentFolderId

export const selectUndoStack = (state: AppState) => state.app.undoStack

export const selectActionInProgress = (state: AppState) =>
  state.app.actionInProgress

export const getUploadingNodes = (state: AppState) => state.app.uploading

export const getInvite = (state: AppState, boardId: string) =>
  state.invites[boardId]

export const getSubscriptionPlans = (state: AppState) => state.plans

export const getBulkActionNodeTypes = (state: AppState) => {
  const { nodes, contentId, folderId } = state.app.bulk
  const folder = getFolder(state, contentId, folderId)

  return (folder?.content || [])
    .filter(({ id }) => nodes.find((node) => node.id === id))
    .map(({ type }) => type)
}

export const campaignsBanners = (state: AppState) => state.campaignsBanners

export const getClientConfig = (state: AppState) => state.app.clientConfig

export const getFirstActivation = (state: AppState) => {
  const { profileInfo } = state

  const activations = profileInfo.plan.activations || []

  const activation = activations[0]
    ? {
        ...activations[0],
        expires: activations[0].expires
          ? convertFirestoreTimestampToDate(activations[0].expires)
          : undefined,
      }
    : undefined

  return activation
}
